const useRegion = () => {
  const config = useRuntimeConfig()

  function region() {
    return config.public.region
  }

  return {
    get region() {
      return region()
    },
    redirectToRegion(opts: { region: string, path: string }) {
      let redirectURL
      if (opts.region === 'china') {
        redirectURL = config.public.chinaUrl
      } else {
        redirectURL = config.public.globalUrl
      }

      if (opts.path) redirectURL = `${redirectURL}${opts.path}`
      redirectURL += '?region_redirect=1'

      return navigateTo(redirectURL, { external: true })
    }
  }
}

export { useRegion }
